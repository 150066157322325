
import { SuInput } from '@thestarweb/ui';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class LangItem extends Vue{
    declare $refs:{
        titleInput: SuInput;
        input: HTMLTextAreaElement;
    };
    @Prop({
        type: String,
        default: '',
    })
    private readonly international!:string;
    @Prop({
        type: String,
        default: '',
    })
    private readonly value!:string;
    private handleValueChange():void {
        this.$emit('update:value', this.$refs.input.value)
    }
    private editTitle = false;
    private currentInputTitle = '';
    private handleEditTitle(){
        this.currentInputTitle = this.international;
        console.log(this.currentInputTitle);
        this.editTitle = true;
        this.$nextTick(() => this.$refs.titleInput.focus())
    }
    private handleStopEditTitle(){
        this.editTitle = false;
        if(this.currentInputTitle){
            this.$emit('update:international', this.currentInputTitle);
        }
    }
}

